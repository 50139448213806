import React from "react"
import MainNav from "../Components/navbar"
import MainFooter from "../Components/footer"
import Seo from "../Components/seo"
import MobileNav from "../Components/mobileNav"
import Helmet from "react-helmet"

const Layout = props => {
  return (
    // <div style={{ minHeight: "100vh", paddingBottom: "100px" }}>
    <div>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js"></script>
      </Helmet>
      <Seo />
      <MainNav />
      {props.children}
      <MainFooter />
    </div>
  )
}

export default Layout
